import React, { useState, useEffect } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios } from "../../../utils/axios";
import { MenuItem, Select, TextField } from "@mui/material";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

type FormProps = {
  distributor: string;
  address: string;
};

const GenerateOrder = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [distributorOptions, setDistributorOptions] = useState([] as any);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [addressOptions, setAddressOptions] = useState([] as any);
  const [productOptions, setProductOptions] = useState([] as any);

  const {t} = useTranslation()

  const [products, setProducts] = useState([
    {
      product: "",
      quantity: 1,
    },
  ]);

  const handleChange = (index, event) => {
    const newInputFields = [...products];

    newInputFields[index][event.target.name] = event.target.value;

    setProducts(newInputFields);
  };

  const handleAddFields = () => {
    setProducts([...products, { product: "", quantity: 1 }]);
  };

  const handleRemoveFields = (index) => {
    const newInputFields = [...products];
    newInputFields.splice(index, 1);
    setProducts(newInputFields);
  };

  const defaultValues: FormProps = {
    distributor: "",
    address: "",
  };

  const getDistibutors = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "user/getDistributors");
      console.log(data);
      setDistributorOptions(
        data?.users.map((item: any) => ({ label: item?.name, id: item?._id }))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getProducts = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "product/getActiveProducts");
      console.log(data);

      setProductOptions(
        data.products.map((item: any) => ({
          label: item?.skuName,
          id: item?._id,
        }))
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getDistibutors();
    getProducts();
  }, []);

  const onSubmit = async (values: FormProps) => {
    console.log(values);

    console.log(products);

    let empty = false;

    products.forEach((item) => {
      if (item.product === "") {
        empty = true;
      }
    });

    if (empty) {
      return toastMessage("Product cannot be empty", "error");
    }

    try {
      setLoading(true);
      const { data } = await callAxios("post", "order/createOrder", {
        ...values,
        products,
      });
      console.log(data);
      toastMessage("Order Created Successfully", "success");
      setLoading(false);
      navigate("/admin/purchase-order");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAddressesByDistributor = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `address/getAddressesByDistributor/${selectedDistributor}`
      );

      console.log(data);

      setAddressOptions(
        data?.addresses.map((item: any) => ({
          label: item?.name,
          id: item?._id,
        }))
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (selectedDistributor !== "") {
      getAddressesByDistributor();
    }
  }, [selectedDistributor]);

  console.log(products);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.generateProductOrder.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.generateProductOrder.description')}              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded">
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <h6 className="text-[#23282D] text-2xl poppins font-medium">
                {t('adminRoute.generateProductOrder.formLabels.createOrder')} 
                </h6>
                <div className="w-full grid grid-cols-2 gap-16 ">
                  <div className="w-full">
                    <SelectElement
                      options={distributorOptions}
                      name="distributor"
                      fullWidth
                      required
                      label={t('adminRoute.generateProductOrder.formLabels.selectDistributor')} 
                      size="small"
                      value={selectedDistributor}
                      onChange={(e: any) => {
                        console.log(e);

                        setSelectedDistributor(e);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectElement
                      options={addressOptions}
                      name="address"
                      required
                      fullWidth
                      label={t('adminRoute.generateProductOrder.formLabels.selectAddress')} 
                      size="small"
                    />
                  </div>
                </div>
                <h6 className="text-[#23282D] text-xl poppins font-medium">
                {t('adminRoute.generateProductOrder.formLabels.selectProducts')}
                </h6>
                <div className="w-full flex flex-col gap-4">
                  {products.map((inputField, index) => (
                    <div className="w-full grid grid-cols-12 gap-16 ">
                      <div className="w-full col-span-5 ">
                        <Select
                          name="product"
                          fullWidth
                          required
                          // label="Select Product"
                          size="small"
                          value={inputField.product}
                          onChange={(event) => handleChange(index, event)}
                        >
                          <MenuItem value="" disabled>
                          {t('adminRoute.generateProductOrder.productFields.selectProduct')}
                          </MenuItem>
                          {productOptions.map((item: any) => (
                            <MenuItem value={item.id}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="w-full col-span-5 ">
                        <TextField
                          name="quantity"
                          placeholder="Enter Quantity"
                          required
                          fullWidth
                          size="small"
                          type="number"
                          label={t('adminRoute.generateProductOrder.productFields.quantity')}
                          value={inputField.quantity}
                          onChange={(event) => handleChange(index, event)}
                        />
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <button
                          className="rounded bg-red-500 py-1 px-4 w-full flex justify-center poppins text-white  "
                          type="button"
                          onClick={() => handleRemoveFields(index)}
                        >
                          -
                        </button>
                      </div>
                      <div className="w-full flex justify-center items-center">
                        <button
                          className="rounded bg-primary py-1 px-4 flex justify-center w-full poppins text-white "
                          type="button"
                          onClick={() => handleAddFields()}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-full flex justify-end">
                  <button
                    className="rounded bg-primary py-1 px-12 poppins text-white w-fit "
                    type="submit"
                  >
                  {t('adminRoute.generateProductOrder.submitButton')}
                  </button>
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default GenerateOrder;
