import React, { useState, useEffect } from "react";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import { Avatar, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { db } from "../../../utils/firebase";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "@firebase/firestore";

const UsersList = ({ selectedUser, setSelectedUser }: any) => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([])

  const getUsers = async () => {
    try {
      const { data } = await callAxios(
        "get",
        `user/getChatUsers?search=${search}`
      );
      console.log(data);
      setUsers(data?.users);
    } catch (error) {
      console.log(error);

      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getUsers();
  }, [search]);

  useEffect(() => {

    if(users.length===0) return setNewUsers([]);

    const newMessageUsers = onSnapshot(
      query(collection(db, "users"), where("newMessage", "==", true)),
      (snapshot) => {
        console.log(snapshot.docs);

        let newUsers = [];

        snapshot.docs.map((doc) => {
          newUsers.push(doc.data());
        });
        console.log(newUsers);

        const messageIds = new Set(newUsers.map((user) => user.userId));
        console.log(messageIds);

        const usersWithMessages = users
          .filter((user) => messageIds.has(user._id))
          .map((user) => ({ ...user, newMessage: true }));

        const usersWithoutMessages = users
          .filter((user) => !messageIds.has(user._id))
          .map((user) => ({ ...user, newMessage: false }));

        // Concatenate the two arrays
        const sortedUsersArray = [
          ...usersWithMessages,
          ...usersWithoutMessages,
        ];

        console.log(sortedUsersArray);
        

        setNewUsers(sortedUsersArray);
      }
    );

    return () => {
      newMessageUsers();
    };
  }, [users]);

  return (
    <div className="w-full flex flex-col gap-4">
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <div className="w-full flex flex-col gap-2">
        {newUsers?.map((user: any) => {
          return (
            <div
              key={user?._id}
              className="w-full rounded cursor-pointer shadow p-2 items-center bg-secondary flex gap-2"
              onClick={() => {
                setSelectedUser(user);
              }}
            >
              <Avatar sx={{ bgcolor: "#719FE5" }}>
                {" "}
                {user?.name?.charAt(0).toUpperCase()}
              </Avatar>
              <div className="flex flex-col gap-3 justify-center items-center w-full ">
                <p className="text text-md text-gray-600 poppins font-medium">
                  {user?.name}
                </p>
                <p className="text text-sm text-gray-400 inter font-normal">
                  {user?.email}
                </p>
              </div>
              {user?.newMessage && (
                <div className="rounded-full bg-red-500 p-1"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UsersList;
