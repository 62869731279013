import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#719FE5", // Customize cell background color
  fontWeight: "bold", // Customize cell text styling
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "#fff",
}));
const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4", // Customize cell background color
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));
const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

type FormProps = {
  materialId: string;
  name: string;
  measurementUnit: string;
};

const MaterialTable = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [materials, setMaterials] = useState([]);

  const [inputValues, setInputValues] = useState<FormProps>({
    materialId: "",
    name: "",
    measurementUnit: "",
  });

  const defaultValues: FormProps = {
    materialId: "",
    name: "",
    measurementUnit: "",
  };

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "post",
        "material/createMaterial",
        values
      );
      console.log(data);

      setLoading(false);
      toastMessage("Material Created Successfullly", "success");
      setInputValues(defaultValues);
      setChecked(!checked);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  const getAllMaterials = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "material/getAllMaterial");
      console.log(data);
      setMaterials(data?.materials);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllMaterials();
  }, [checked]);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.materialPage.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.materialPage.description')}              </p>
            </div>
          </div>
          <div className="w-full  flex flex-col gap-6 ">
            <FormContainer values={inputValues} onSuccess={onSubmit}>
              <div className="w-full grid grid-cols-4 gap-12 items-end ">
                <div className="w-full flex flex-col gap-1">
                  <p className="poppins text-md font-semibold">
                  {t('adminRoute.materialPage.formLabels.materialId')}<span className="text-red-500">*</span>
                  </p>
                  <TextFieldElement
                    name="materialId"
                    //   label="Distibutor ID"
                    required
                    variant="standard"
                    fullWidth
                    value={inputValues.materialId}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        materialId: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="poppins text-md font-semibold">
                  {t('adminRoute.materialPage.formLabels.materialName')}<span className="text-red-500">*</span>
                  </p>
                  <TextFieldElement
                    name="name"
                    required
                    variant="standard"
                    fullWidth
                    value={inputValues.name}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <p className="poppins text-md font-semibold">
                  {t('adminRoute.materialPage.formLabels.measurementUnit')}<span className="text-red-500">*</span>
                  </p>
                  <SelectElement
                    name="measurementUnit"
                    required
                    variant="standard"
                    fullWidth
                    options={[
                      {
                        label: "KG",
                        id: "KG",
                      },
                      {
                        label: "Litre",
                        id: "Litre",
                      },
                      {
                        label: "Pcs",
                        id: "Pcs",
                      },
                    ]}
                    value={inputValues.measurementUnit}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        measurementUnit: e,
                      })
                    }
                  />
                </div>
                <button
                  className="rounded bg-primary poppins text-white w-40 h-8 flex items-center justify-center "
                  type="submit"
                >
                  {t('adminRoute.materialPage.formSubmitButton')}
                </button>
              </div>
            </FormContainer>
          </div>
          <div className="w-full bg-white p-3 rounded shadow">
            <Table
              sx={{
                borderSpacing: "0 15px !important",
                borderCollapse: "separate !important",
              }}
            >
              <TableHead>
                <CustomTableHeadRow>
                  <CustomTableHeadCell>{t('adminRoute.materialPage.tableHeaders.materialId')}</CustomTableHeadCell>
                  <CustomTableHeadCell> {t('adminRoute.materialPage.tableHeaders.materialName')}</CustomTableHeadCell>
                  <CustomTableHeadCell>{t('adminRoute.materialPage.tableHeaders.measurementUnit')}</CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                  <CustomTableHeadCell></CustomTableHeadCell>
                </CustomTableHeadRow>
              </TableHead>
              <TableBody>
                {materials.map((user: any) => {
                  return (
                    <CustomTableBodyRow
                      sx={{
                        marginBottom: "10px !important",
                      }}
                      key={user?._id}
                    >
                      <CustomTableBodyCell
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                      >
                        {user.materialId}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>{user?.name}</CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {user?.measurementUnit}
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default MaterialTable;
